.providers-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-bottom: 2rem;
}

.providers-title {
  text-align: center;
  font-size: 2.25rem;
  margin-top: 2rem;
  margin-bottom: 2rem;
  background: #fa600f;
  background: linear-gradient(to right, #fa600f 0%, #943909 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.bar {
  position: relative;
  height: 400px;
  width: 300px;
}

@media only screen and (min-width: 1024px) {
  .bar {
    height: 700px;
    width: 600px;
  }
}
