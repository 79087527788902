.hero-container {
  box-sizing: border-box;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  box-sizing: border-box;
}

.hero-image {
  width: fit-content;
  height: 600px;
  object-fit: cover;
}

.sub-container {
  position: absolute;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  color: white;
  z-index: 1;
}

.hero-subtitle {
  font-size: 1.7rem;
}

.sub-container h3 {
  font-size: 1.6rem;
}

/*.sub-container h3 span {
  text-decoration: line-through #3c4242 4px;
}*/

.hero-h3 {
  color: #fa6413;
}

.sub-container h2 {
  margin-bottom: 7rem;
}

.button-hero {
  background-color: #ffff;
  color: #3c4242;
  width: 140px;
  height: 42px;
  font-size: 1rem;
  border-radius: 0.5rem;
  cursor: pointer;
  font-weight: 600;
  border: none;
  margin-top: 2rem;
}

/*Benefits*/

.card-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 3rem;
}

.card {
  width: 20rem;
  margin-bottom: 2rem;
  text-align: center;
}

.card-h3 {
  font-size: 1.5rem;
}

.card-p {
  font-size: 1.2rem;
  text-align: right;
  margin-right: 1rem;
  color: #666;
}

.customerIcon,
.secureIcon,
.fa-truck_icon {
  height: 3rem;
  width: 3rem;
  margin-right: 10px;
}

@media (min-width: 800px) {
  .hero-image {
    width: 100%;
    height: 872px;
  }

  .hero-subtitle {
    font-size: 2.5rem;
  }

  .sub-container h3 {
    font-size: 2.2rem;
  }

  .hero-container {
    justify-content: left;
  }

  .sub-container {
    margin-left: 9rem;
  }

  /*Benefits*/

  .card-container {
    flex-direction: row;
    justify-content: center;
    display: flex;
    margin: 20px 0;
  }

  .card {
    width: 35rem;
    text-align: left;
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    width: 30%;
  }

  .card-h3 {
    font-size: 1.5rem;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .card-p {
    margin-left: 5rem;
    font-size: 1.3rem;
    color: #666;
    text-align: center;
  }

  #freeshipping {
    margin-right: 4rem;
  }

  #users {
    text-align: center;
  }

  .customerIcon,
  .secureIcon,
  .fa-truck_icon {
    margin-right: 10px;
    width: 70px;
    height: 70px;
  }
}
