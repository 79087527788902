.line {
  margin-top: 5rem;
  width: 90%;
  height: 2px;
  background-color: #d5cbe5;
  margin-bottom: 0.5rem;
  margin-left: 1rem;
}

.footer {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 90%;
  margin-left: 2rem;
  font-size: 1.2rem;
  margin-bottom: 1rem;
}

.logo-footer {
  align-items: center;
}

.footer-title {
  text-align: center;
  color: #d5cbe5;
}

.footer-links {
  display: flex;
  margin-top: 2rem;
}

.footer-item {
  text-decoration: none;
  color: rgb(0, 0, 0);
  margin-top: 0.5rem;
}

@media (min-width: 800px) {
  .line {
    margin-top: 5rem;
    text-align: center;
    width: 90%;
    height: 2px;
    background-color: #d5cbe5;
    margin-bottom: 0.5rem;
    margin-left: 5rem;
  }

  .footer {
    flex-direction: row;
    margin: 0 auto;
    margin-top: 1rem;
    margin-bottom: 2rem;
  }

  .footer-title {
    text-align: left;
  }
}
