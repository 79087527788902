.restaurants-title {
  font-size: 2.25rem;
  text-align: center;
  margin-top: 4rem;
  margin-bottom: 4rem;
}

.cards-container {
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  /*margin-left: 6.5rem;*/
}

.restaurant-card {
  /*margin-right: 6rem;*/
  cursor: pointer;
}

.restaurant-img {
  border-radius: 50%;
  width: 10rem;
  height: 10rem;
}

.card-title {
  text-align: center;
  margin-top: 0.5rem;
  font-size: 1.2rem;
  font-weight: bold;
  margin-bottom: 1.5rem;
}

@media (min-width: 765px) {
  .cards-container {
    flex-direction: row;
    flex-wrap: wrap;
    margin-left: 5rem;
  }

  .restaurant-card {
    margin-right: 6rem;
  }

  .restaurant-img {
    width: 12rem;
    height: 12rem;
  }
}

@media (min-width: 1024px) {
  .restaurant-card {
    margin-right: 9.5rem;
  }

  .restaurants-title {
    text-align: left;
    margin-left: 6.5rem;
  }

  .restaurant-img:hover {
    transition: transform 0.2s ease-in-out;
    transform: scale(1.05);
  }
}

@media (min-width: 1800px) {
}
